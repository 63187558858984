<template>
  <div class="valign-wrapper">
    <div class="row" style="width: 15cm; min-height: 10cm; position: relative">

      <div class="card hoverable col s12 left-align grey lighten-5" >
        <div class="card-content">
          <div class="row "></div>
          <!--        BEGIN CARD-->
          <div class="row">
            <div class="col s1">
              <!--          SPACE FOR PROFILE PIC-->
            </div>
            <div class="col s10">
              <Editbox id="displayName" placeholder="DisplayName"  class="labels" v-model="editDisplayName"/>
            </div>
          </div>
          <!--First Last names-->
          <div class="row">
            <div class="col s1">
              <!--          SPACE FOR PROFILE PIC-->
            </div>
            <div class="col s5">
              <Editbox id="FirstName" placeholder="Firstname"   class="labels disabled" v-model="editFirstname" readonly />
            </div>
            <div class="col s5">
              <Editbox id="LastName" placeholder="Lastname"  class="labels disabled" v-model="editLastname" readonly />
            </div>
          </div>
<!--        Email-->
          <div class="row">
            <div class="col s1"></div>
            <div class="col s10">
              <Editbox id="Email" placeholder="Email"  class="labels disabled"  v-model="editEmail" readonly />
            </div>
          </div>
          <!--    Age & Sex-->
          <div class="row">
            <div class="col s1">
              <!--          SPACE FOR PROFILE PIC-->
            </div>
            <div class="col s5">
              <Select :items="ages" class="sizeMiddle" placeholder="Age" v-model="editAge" />
            </div>
            <div class="col s5">
              <Select :items="sexes" class="sizeMiddle"  placeholder="Sex" v-model="editSex" />
            </div>
          </div>

          <div class="row">
            <div class="col s1"></div>
            <div class="col s10">
              <Alert v-if="feedbackMessage.msg !== ''" :error="feedbackMessage.error" >{{ feedbackMessage.msg }}</Alert>
            </div>
          </div>


          <div class="row" v-if="verifiedMessage.msg !== ''">
            <div class="col s1"></div>
            <div class="col s10">
              <Alert  :error="verifiedMessage.error" >{{ verifiedMessage.msg }}</Alert>
            </div>
          </div>

          <div class="row" v-if="verifiedMessage.msg !== ''">
            <div class="col s1"></div>
            <div class="col s6">
              <div class="btn z-depth-0" @click="$store.dispatch('resendVerification')" >Resend Verification Email</div>
            </div>
            <div class="col s4">
              <div class="btn z-depth-0" >Update Status</div>
            </div>
          </div>



        <!--END CARD-->


        </div>

        <div class="card-action right-align">
          <div class="btn z-depth-0 green waves-effect waves-light" @click="updateProfile" >Save changes</div>
        </div>

      </div>
      <img v-if="thisUser.photoURL" :src="thisUser.photoURL" alt="" class="profilepic2 hide-on-large-only " >
      <img v-if="thisUser.photoURL" :src="thisUser.photoURL" alt="" class="profilepic hide-on-med-and-down " >
      <div v-if="!thisUser.photoURL" class="profilepic valign-wrapper blue-grey" style="background-color: black; overflow: hidden">
        <div class="row"><h1 style="font-size: 200px">{{ thisUser.displayName[0] }}</h1></div>
      </div>
      <div :class="`verifiedLabel ${thisUser.emailVerified? 'green-text' : 'red-text'} text-darken-3`"
           :title="thisUser.emailVerified? 'Verified' : 'Unverified'">
        <i class="material-icons">{{ thisUser.emailVerified? 'done' : 'info' }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import Editbox from "../components/Controls/Editbox";
import Select from "../components/Controls/Select";
import Alert from "../components/Controls/Alert";

export default {
  name: "Profile",
  components: {Editbox, Select, Alert},
  data(){
    return {
      editFirstname: "",                             // profile
      editLastname: "",                               // profile
      editEmail: "",                                  // profile & user
      editDisplayName: "",                            // user
      editAge: "Age",                             // profile
      editSex: "Sex",                               // profile
      sexes: ['unspecified', 'male', 'female', 'other'],
      ages: Array.from({length: 90}, (x, i) => String(i+18) ),
    }
  },
  computed: {
    thisUser() {
      return this.$store.state.auth.storage.user
    },
    thisProfile() {
      return this.$store.state.userProfile.storage.profile
    },
    feedbackMessage() {
      return this.$store.state.userProfile.cacheMsg
    },
    verifiedMessage() {
      return this.$store.state.userProfile.verifiedMessage
    }
  },
  beforeMount() {
    this.$store.dispatch("startLoading")
  },
  mounted() {
    this.$store.dispatch("checkVerification")
    this.$store.dispatch("fetchProfile").then(() => {
      this.editFirstname = this.thisProfile.firstname;
      this.editLastname = this.thisProfile.lastname;
      this.editEmail = this.thisUser.email;
      this.editDisplayName = this.thisUser.displayName;
      this.editSex = this.thisProfile.sex;
      this.editAge = String((new Date()).getFullYear() - this.thisProfile.birthYear)
    }).then(() => {
      this.$store.dispatch("finishLoading")
    })
  },
  methods: {
    updateProfile() {
      this.$store.dispatch("updateProfile", {
        displayName: this.editDisplayName,
        age: this.editAge,
        sex: this.editSex
      })
    }
  }
}
</script>








<style scoped>
.verifiedLabel {
  position: absolute;
  left: 2em;
  top: calc(4cm + 1em);
  z-index: 2;
  cursor: default;
}

.profilepic {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  border-width: 1mm;
  border-color: #ededed;
  box-shadow: -1px 1px 5px #545454;
  object-fit: cover;
  width: 4cm;
  height: 4cm;
  left: -3cm;
  top: 1cm;
  z-index: 2;

}

.profilepic2 {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  border-width: 1mm;
  border-color: #ededed;
  box-shadow: -1px 1px 5px #545454;
  object-fit: cover;
  width: 4cm;
  height: 4cm;
  left: 0;
  top: -1cm;
  z-index: 2;

}

.disabled {
  --edit-bg-color-focus: transparent;
}


.labels {
  background-color: transparent;
  --visible-label: hidden;
  --border-style: hidden;
  border-style: none;
  --label-bg-color: #005e80;
  margin-top: 0;
}
</style>
<template>
  <div class="mustafa-textfield" >
    <input type="text" :id="id" ref="textinput" :value="value"  @input="updateValue"  @blur="$emit('blur')" required :readonly="readonly">
    <label :for="id">{{ placeholder }}</label>
    <div class="editBack"></div>
  </div>
</template>

<script>
export default {
  name: "EditBox",
  emits: ['blur'],
  props: {
    readonly: {
      Type: Boolean,
      default() {
        return false;
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    },
    placeholder: {
      type: String,
      default() {
        return 'Enter text here'
      }
    },
    id: {
      type: String,
      default() {
        return 'EditBoxId'
      }
    },
  },
  methods: {
    updateValue(){
      this.$emit('input', this.$refs.textinput.value)
    },
    focus(){
      this.$refs.textinput.focus()
    }
  }
}
</script>

<style >
.mustafa-textfield {
  --label-bg-color: #076fde;
  --visible-label: visible;
  --label-bg-color-hover: transparent;
  --edit-bg-color: inherit;
  --edit-bg-color-focus: white;
  --border-style: solid;

  position: relative;
  margin-top: 0.5em;
  /*width: fit-content;*/
  cursor: text;
  padding: 0.1em 0.4em 0.1em 0.1em !important;
  margin-left: auto;
  margin-right: auto;
}


.mustafa-textfield input[type="text"]{
  position: relative;
  outline-style: none !important;
  border-style: none !important;
  background-color: var(--edit-bg-color);
  /*font-size: 0.8em;*/
  border-width: 0;
  /*padding: 7px 5px 5px 5px;*/
  padding: 0.3em 0.3em 0.3em 0.3em;
  margin: 0 0.5em 0 0;
  width: calc(100% - 0.5em);
  z-index: 1;
}


.mustafa-textfield input[type="text"]:focus {
  /*background-color: var(--edit-bg-color-focus);*/
  outline-style: none !important;
  border-style: none !important;
}


/* label */
.mustafa-textfield label {
  background-color: var(--label-bg-color-hover);

  position: absolute;
  font-size: 1em;
  top: 0.8em;
  left: 5px;
  transition: 0.2s ease-out;
  cursor: text;
  /*padding:  2px 4px 1px 4px;*/
  padding:  0.3em 0.4em 0.2em 0.4em;
  visibility: var(--visible-label);
  z-index: 1;
}

.mustafa-textfield input[type="text"]:hover + label {
  visibility: visible;
}

.mustafa-textfield input[type="text"]:focus + label,
.mustafa-textfield input[type="text"]:valid + label,
.mustafa-textfield input[type="text"]:read-only + label
{
  transform: translateY(-14px);
  font-size: min(0.6em, 12px) ;
  background-color: var(--label-bg-color);
  border-radius: 0.3em;
  box-shadow: -1px 1px 2px #545454;
  color: white !important;
}
.mustafa-textfield input[type="text"]:focus + label {
  visibility: visible;
}
.mustafa-textfield input[type="text"]:invalid + label {
  visibility: visible;
}

.editBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--edit-bg-color);
  z-index: 0;
  border-style: var(--border-style);
  border-width: 0.5px;
  border-radius: 0.3rem;
  border-color: #2c3e50;
  transition: 0.15s ;
}


.mustafa-textfield input[type="text"]:focus + label + .editBack {
  background-color: var(--edit-bg-color-focus);
  border-color: var(--label-bg-color);
  border-style: solid;
  border-width: 2px;
}
</style>
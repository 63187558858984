<!-- onClick(p) p refers (this) in parent -->

<template>
  <div class=" dropdown-menu" @mouseleave="unfold" @blur="unfold" :style="`min-width: max(${placeholder.length}em, ${maxWidth }em) `" :tabindex="tabindex" @keyup.space="fold">

    <div class="dropdown-menu-title waves-effect waves-block" @click="fold" :style="`min-width: max(${placeholder.length}em,  ${maxWidth }em); background-color: ${isVisible? 'white': 'transparent'};`" >
      {{value!==''? value : placeholder}}
    </div>
    <div v-if="isVisible" class="sub-menu " ref="submenu" :style="`top: -${itemsVisible.length }em; left: 50%; min-width: ${placeholder.length }em; `"
    >
      <div v-for="(item, i) in itemsVisible" :key="i" class="menu-item " :style="'top: calc(100% + '+ i + 'em )'"
           @click="performClick(item)" >
        {{ item }}
      </div>
    </div>
    <div class="dropdown-menu-icon">
      <i class="material-icons">arrow_drop_down</i>
    </div>
  </div>
</template>

<script>

export default {
  name: "Select",
  props: {
    value: {
      type: String,
      default() {
        return 'default'
      }
    },
    tabindex: {
      type: Number,
      required: false,
      default: 1
    },
    items: {
      type: Array,
      default() {
        return ['a', 'b']
      }
    },
    placeholder:{
      type: String,
      default() {
        return 'Choose'
      }
    }
  },
  data() {
    return {
      isVisible: false,
      itemsVisible: ['asd', 'fsd'],
      offset: 0,
      maxWidth: 6,
    }
  },
  methods:{
    fold(){
      this.isVisible = true;
    },
    unfold(){
      this.isVisible = false;
    },
    performClick(val){
      if (val !== 'DOWN' && val !== 'UP'){
        this.isVisible = false;
        this.$emit('input', val);
      }
      else if(val === 'DOWN')
      {
        this.offset += 10
        this.scroll();
      }
      else
      {
        this.offset -= 10
        this.scroll();
      }
    },
    scroll(){
      this.itemsVisible = this.items.slice(this.offset, this.offset + 10)
      if (this.offset + 10 < this.items.length)
      {
        this.itemsVisible.push('DOWN')
      }
      if (this.offset > 0)
      {
        this.itemsVisible.unshift('UP')
      }
    }

  },
  created() {



    if(this.items.length > 10){
      this.itemsVisible = this.items.slice(this.offset, 10)
      this.itemsVisible.push('DOWN')
    }
    else
    {
      this.itemsVisible = this.items
    }
  },
  mounted() {
    this.$emit('input', this.value);
    // this.maxWidth = this.items.sort((a, b) =>  b.length - a.length )[0].length;
  }
}
</script>

<style scoped>

.dropdown-menu {
  --border-style: solid;
  position: relative;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  outline-style: none;
  cursor: pointer;
}

.dropdown-menu .dropdown-menu-icon {
  position: absolute;
  top: 20%;
  right: 1em;
}

.dropdown-menu .btn {
  width: 100%;
  outline-style: none;
}

.dropdown-menu-title {
  position: center;
  height: border-box;
  color: black;
  /*background-color: var(--edit-bg-color);*/
  z-index: 0;
  border-style: var(--border-style);
  border-width: 0.5px;
  border-radius: 0.3rem;
  border-color: #2c3e50;
  padding:  0.6em 3em 0.6em 0.4em;
  outline-style: none;
  width: 100%;
}


.sub-menu {
  position: absolute;
  background-color: #dedede;
  min-height: max-content;
  transform: translate(-50%);
  padding: 1px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  border-radius: 2px;
  z-index: 1000;
}


.dropdown-menu .sub-menu .menu-item {
  padding: 10px 15px 10px 15px;
  font-size: 0.9em;
  cursor: default;
}

.dropdown-menu .sub-menu .menu-item:hover {
  background-color: #c2c2c2;
}
</style>
<template>
<div :class="`mus-alert ${error? 'red' : 'green'} lighten-5 ${error? 'red' : 'green'}-text text-darken-4`" >
 <slot></slot>
</div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    error: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped>
.mus-alert {
  border-width: 0.5px;
  border-style: solid;
  border-radius: 0.3rem;
  padding: 1px 1em 1px 1em;
}
</style>